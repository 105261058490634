import React from "react";
import { BgTextStyle } from '../utils/globalStyles';
import FlexBox from "./FlexBox";
import { SemiSpan } from "./Typography";
import Icon from "./icon/Icon";

export interface CategorySectionHeaderProps {
  title?: string;
  seeMoreLink?: string;
  iconName?: string;
}

const CategorySectionHeader: React.memo<CategorySectionHeaderProps> = ({
  title,
  seeMoreLink,
  iconName,
}) => {

  return (
    <FlexBox className="section-header" justifyContent="space-between" alignItems="center" mb="1.5rem">
      <FlexBox alignItems="center">
        {iconName && (
          <Icon defaultcolor="auto" mr="0.5rem">
            {iconName}
          </Icon>
        )}

        <BgTextStyle className="categ-title">
          {title}
        </BgTextStyle>

      </FlexBox>

      {seeMoreLink && (

        <a>
          <FlexBox alignItems="center" ml="0.5rem" color="text.muted">
            <SemiSpan mr="0.5rem">View all</SemiSpan>
            <Icon size="12px" defaultcolor="currentColor">
              right-arrow
            </Icon>
          </FlexBox>
        </a>

      )}
    </FlexBox>
  );
};

export default CategorySectionHeader;
